<template>
  <div class="hizmet-sozlesmesi">
    <div>
      <p><strong>Genel Hizmet Sözleşmesi</strong></p>
      <p><strong>A. Tanımı</strong></p>
      <p>
        <strong>MADDE 393- </strong>Hizmet sözleşmesi, işçinin işverene bağımlı olarak belirli veya
        belirli olmayan süreyle işgörmeyi ve işverenin de ona zamana veya yapılan işe göre ücret
        ödemeyi üstlendiği sözleşmedir.
      </p>
      <p>
        İşçinin işverene bir hizmeti kısmi süreli olarak düzenli biçimde yerine getirmeyi üstlendiği
        sözleşmeler de hizmet sözleşmesidir.
      </p>
      <p>
        Genel hizmet sözleşmesine ilişkin hükümler, kıyas yoluyla çıraklık sözleşmesine de
        uygulanır; özel kanun hükümleri saklıdır.
      </p>
      <p>
        <strong>B. Kurulması<br /> </strong>
      </p>
      <p>
        <strong>MADDE 394-</strong> Hizmet sözleşmesi, kanunda aksine bir hüküm olmadıkça özel bir
        şekle bağlı değildir.<span id="more-1495"></span>
      </p>
      <p>
        Bir kimse, durumun gereklerine göre ancak ücret karşılığında yapılabilecek bir işi belli bir
        zaman için görür ve bu iş de işveren tarafından kabul edilirse, aralarında hizmet sözleşmesi
        kurulmuş sayılır.
      </p>
      <p>
        Geçersizliği sonradan anlaşılan hizmet sözleşmesi, hizmet ilişkisi ortadan kaldırılıncaya
        kadar, geçerli bir hizmet sözleşmesinin bütün hüküm ve sonuçlarını doğurur.
      </p>
      <p><strong>C. İşçinin borçları</strong></p>
      <p><strong>I. Bizzat çalışma borcu</strong></p>
      <p>
        <strong>MADDE 395- </strong>Sözleşmeden veya durumun gereğinden aksi anlaşılmadıkça, işçi
        yüklendiği işi bizzat yapmakla yükümlüdür.
      </p>
      <p><strong>II. Özen ve sadakat borcu</strong></p>
      <p>
        <strong>MADDE 396- </strong>İşçi, yüklendiği işi özenle yapmak ve işverenin haklı
        menfaatinin korunmasında sadakatle davranmak zorundadır.
      </p>
      <p>
        İşçi, işverene ait makineleri, araç ve gereçleri, teknik sistemleri, tesisleri ve taşıtları
        usulüne uygun olarak kullanmak ve bunlarla birlikte işin görülmesi için kendisine teslim
        edilmiş olan malzemeye özen göstermekle yükümlüdür.
      </p>
      <p>
        İşçi, hizmet ilişkisi devam ettiği sürece, sadakat borcuna aykırı olarak bir ücret
        karşılığında üçüncü kişiye hizmette bulunamaz ve özellikle kendi işvereni ile rekabete
        girişemez.
      </p>
      <p>
        İşçi, iş gördüğü sırada öğrendiği, özellikle üretim ve iş sırları gibi bilgileri, hizmet
        ilişkisinin devamı süresince kendi yararına kullanamaz veya başkalarına açıklayamaz.
        İşverenin haklı menfaatinin korunması için gerekli olduğu ölçüde işçi, hizmet ilişkisinin
        sona ermesinden sonra da sır saklamakla yükümlüdür.
      </p>
      <p><strong>III. Teslim ve hesap verme borcu</strong></p>
      <p>
        <strong>MADDE 397- </strong>İşçi, üstlendiği işin görülmesi sırasında üçüncü kişiden işveren
        için aldığı şeyleri ve özellikle paraları derhâl ona teslim etmek ve bunlar hakkında hesap
        vermekle yükümlüdür.
      </p>
      <p>
        İşçi, hizmetin ifasından dolayı elde ettiği şeyleri de derhâl işverene teslim etmekle
        yükümlüdür.
      </p>
      <p><strong>IV. Fazla çalışma borcu</strong></p>
      <p>
        <strong>MADDE 398- </strong>Fazla çalışma, ilgili kanunlarda<strong> </strong>belirlenen
        normal çalışma süresinin üzerinde ve işçinin rızasıyla yapılan çalışmadır. Ancak, normal
        süreden daha fazla çalışmayı gerektiren bir işin yerine getirilmesi zorunluluğu doğar, işçi
        bunu yapabilecek durumda bulunur ve aynı zamanda kaçınması da dürüstlük kurallarına aykırı
        olursa işçi, karşılığı verilmek koşuluyla, fazla çalışmayı yerine getirmekle yükümlüdür.
      </p>
      <p>Özel kanunlardaki hükümler saklıdır.</p>
      <p><strong>V. Düzenlemelere ve talimata uyma borcu</strong></p>
      <p>
        <strong>MADDE 399- </strong>İşveren, işin görülmesi ve işçilerin işyerindeki davranışlarıyla
        ilgili genel düzenlemeler yapabilir ve onlara özel talimat verebilir. İşçiler, bunlara
        dürüstlük kurallarının gerektirdiği ölçüde uymak zorundadırlar.
      </p>
      <p><strong>VI. İşçinin sorumluluğu</strong></p>
      <p>
        <strong>MADDE 400- </strong>İşçi, işverene kusuruyla verdiği her türlü zarardan sorumludur.
      </p>
      <p>
        Bu sorumluluğun belirlenmesinde; işin tehlikeli olup olmaması, uzmanlığı ve eğitimi
        gerektirip gerektirmemesi ile işçinin işveren tarafından bilinen veya bilinmesi gereken
        yetenek ve nitelikleri göz önünde tutulur.
      </p>
      <p><strong>D. İşverenin borçları</strong></p>
      <p><strong>I. Ücret ödeme borcu</strong></p>
      <p><strong>1. Ücret</strong></p>
      <p><strong>a. Genel olarak</strong></p>
      <p>
        <strong>MADDE 401- </strong>İşveren, işçiye sözleşmede veya toplu iş sözleşmesinde
        belirlenen; sözleşmede hüküm bulunmayan hâllerde ise, asgari ücretten az olmamak üzere emsal
        ücreti ödemekle yükümlüdür.
      </p>
      <p><strong>b. Fazla çalışma ücreti</strong></p>
      <p>
        <strong>MADDE 402-</strong> İşveren, fazla çalışma için işçiye normal çalışma ücretini en az
        yüzde elli fazlasıyla ödemekle yükümlüdür.
      </p>
      <p>
        İşveren, işçinin rızasıyla fazla çalışma ücreti yerine, uygun bir zamanda fazla çalışmayla
        orantılı olarak izin verebilir.
      </p>
      <p><strong>c. İşin sonucundan pay alma</strong></p>
      <p>
        <strong>MADDE 403-</strong> Sözleşmeyle işçiye ücretle birlikte üretilenden, cirodan veya
        kârdan belli bir pay verilmesi kararlaştırılmışsa, hesap dönemi sonunda bu pay, yasal
        hükümler veya genellikle kabul edilmiş ticari esaslar göz önünde tutularak belirlenir.
      </p>
      <p>
        İşçiye belli bir pay verilmesi kararlaştırılan hâllerde, payın hesaplanmasında uyuşulamazsa
        işveren, işçiye veya onun yerine, birlikte kararlaştırdıkları ya da hâkimin atadığı
        bilirkişiye bilgi vermek ve bilginin dayanağını oluşturan işletmeyle ilgili defter ve
        belgeleri incelemesine sunmak; kârdan bir pay verilmesi kararlaştırılmışsa, işveren işçiye,
        istemi üzerine ayrıca yıl sonu kâr zarar cetvelini vermek zorundadır.
      </p>
      <p><strong>d. Aracılık ücreti</strong></p>
      <p>
        <strong>MADDE 404-</strong> İşçiye belli işlerde aracılık yapması karşılığında işverence bir
        ücret ödeneceği kararlaştırılmışsa, aracılık yapılan işlemin üçüncü kişi ile geçerli olarak
        kurulmasıyla işçinin istem hakkı doğar.
      </p>
      <p>
        Borçların kısım kısım ifa edileceği sözleşmeler ile sigorta sözleşmelerinde, her kısma
        ilişkin ücret isteminin bu kısma ilişkin borcun muaccel olmasıyla veya yerine getirilmesiyle
        doğacağı yazılı olarak kararlaştırılabilir.
      </p>
      <p>
        İşçinin aracılığı suretiyle işveren ile üçüncü kişi arasında kurulan sözleşme, işveren
        tarafından kusuru olmaksızın ifa edilmezse veya üçüncü kişi borçlarını yerine getirmezse,
        ücret istemine yönelik hak sona erer. Sadece kısmi ifa hâlinde, ücretten orantılı olarak
        indirim yapılır.
      </p>
      <p>
        Sözleşmeyle işçiye, kendisine ödenecek aracılık ücretinin hesabını tutma yükümlülüğü
        getirilmemişse, işveren işçiye ücretin muaccel olduğu her dönem için, bu ücrete tabi
        işlemleri de içeren yazılı hesap vermekle yükümlüdür.
      </p>
      <p>
        Hesabı gözden geçirme ihtiyacı ortaya çıkarsa işveren, işçiye veya onun yerine, birlikte
        kararlaştırdıkları ya da hâkimin atadığı bilirkişiye bilgi vermek ve bilginin dayanağını
        oluşturan işletmeyle ilgili defter ve belgeleri onun incelemesine sunmak zorundadır.
      </p>
      <p><strong>e. İkramiye</strong></p>
      <p>
        <strong>MADDE 405-</strong> İşveren, bayram, yılbaşı ve doğum günü gibi belirli günler
        dolayısıyla işçilerine özel ikramiye verebilir. Ancak, işçilerin ikramiyeyi istem hakları bu
        konuda anlaşmanın veya çalışma şartının ya da işverenin tek taraflı taahhüdünün varlığı
        hâlinde doğar.
      </p>
      <p>
        Hizmet sözleşmesi ikramiyenin verildiği dönemden önce sona ermişse, ikramiyenin çalıştığı
        süreye yansıyan bölümü ödenir.
      </p>
      <p><strong>2. Ücretin ödenmesi</strong></p>
      <p><strong>a. Ödeme süresi</strong></p>
      <p>
        <strong>MADDE 406-</strong> Aksine âdet olmadıkça, işçiye ücreti her ayın sonunda ödenir.
        Ancak, hizmet sözleşmesi veya toplu iş sözleşmesiyle daha kısa ödeme süreleri
        belirlenebilir.
      </p>
      <p>
        Daha kısa bir ödeme süresi kararlaştırılmamışsa veya aksine âdet yoksa, aracılık ücreti her
        ayın sonunda ödenir. Ancak, işlemlerin yapılması altı aydan daha uzun bir süre gerektirdiği
        takdirde, aracılık ücreti asıl ücrete ek olarak kararlaştırılmışsa, yazılı anlaşmayla ödeme
        daha ileri bir tarihe bırakılabilir.
      </p>
      <p>
        Asıl ücrete ek olarak üretilenden pay verilmesi öngörülen hâllerde, ürün payı belirlenir
        belirlenmez, cirodan veya kârdan pay verilmesi kararlaştırılan hâllerde ise payın, hesap
        dönemini izleyen en geç üç ay içinde belirlenerek ödenmesi şarttır.
      </p>
      <p>
        İşveren, işçiye zorunlu ihtiyacının ortaya çıkması hâlinde ve hakkaniyet gereği ödeyebilecek
        durumda ise, hizmetiyle orantılı olarak avans vermekle yükümlüdür.
      </p>
      <p><strong>b. Ücretin korunması</strong></p>
      <p>
        <strong>MADDE 407- </strong>Çalıştırılan işçilerin ücret, prim, ikramiye ve bu nitelikteki
        her çeşit istihkaktan o ay içinde ödenenlerin özel olarak açılan banka hesabına yatırılmak
        suretiyle ödenmesi hususunda; tabi olduğu vergi mükellefiyeti türü, işletme büyüklüğü,
        çalıştırdığı işçi sayısı, işyerinin bulunduğu il ve benzeri unsurları dikkate alarak iş
        sahiplerini zorunlu tutmaya, banka hesabına yatırılacak ücret, prim, ikramiye ve bu
        nitelikteki her çeşit istihkakın, brüt ya da kanuni kesintiler düşüldükten sonra kalan net
        miktar üzerinden olup olmayacağını belirlemeye Çalışma ve Sosyal Güvenlik Bakanlığı, Maliye
        Bakanlığı ve Hazine Müsteşarlığından sorumlu Devlet Bakanlığı müştereken yetkilidir.
        Çalıştırdığı işçilerin ücret, prim, ikramiye ve bu nitelikteki her çeşit istihkakını özel
        olarak açılan banka hesapları vasıtasıyla ödeme zorunluluğuna tabî tutulan iş sahipleri,
        işçilerinin ücret, prim, ikramiye ve bu nitelikteki her çeşit istihkakını özel olarak açılan
        banka hesapları dışında ödeyemezler. Her ödeme döneminde, işçiye hesap pusulası verilir.
        İşçilerin ücret, prim, ikramiye ve bu nitelikteki her çeşit istihkaklarının özel olarak
        açılan banka hesabına yatırılmak suretiyle ödenmesine ilişkin diğer ûsul ve esaslar, anılan
        bakanlıklarca müştereken çıkarılacak yönetmelikle düzenlenir.
      </p>
      <p>
        İşveren, işçiden olan alacağı ile ücret borcunu işçinin rızası olmadıkça takas
        edemez.<strong> </strong>Ancak, işçinin kasten sebebiyet verdiği yargı kararıyla sabit bir
        zarardan doğan alacaklar, ücretin haczedilebilir kısmı kadar takas edilebilir.
      </p>
      <p>Ücretin işveren lehine kullanılacağına ilişkin anlaşmalar geçersizdir.</p>
      <p><strong>3. İşgörme ediminin ifasının engellenmesi hâlinde ücret</strong></p>
      <p><strong>a. İşverenin temerrüdü hâlinde</strong></p>
      <p>
        <strong>MADDE 408-</strong> İşveren, işgörme ediminin yerine getirilmesini kusuruyla
        engellerse veya edimi kabulde temerrüde düşerse, işçiye ücretini ödemekle yükümlü olup,
        işçiden bu edimini daha sonra yerine getirmesini isteyemez. Ancak, işçinin bu engelleme
        sebebiyle yapmaktan kurtulduğu giderler ile başka bir iş yaparak kazandığı veya kazanmaktan
        bilerek kaçındığı yararlar ücretinden indirilir.
      </p>
      <p><strong>b. İşçinin çalışmayı durdurması hâlinde</strong></p>
      <p>
        <strong>MADDE 409- </strong>Uzun süreli bir hizmet ilişkisinde işçi, hastalık, askerlik veya
        kanundan doğan çalışma ve benzeri sebeplerle kusuru olmaksızın, iş gördüğü süreye oranla
        kısa bir süre için işgörme edimini ifa edemezse işveren, başka bir yolla karşılanmadığı
        takdirde, o süre için işçiye hakkaniyete uygun bir ücret ödemekle yükümlüdür.
      </p>
      <p><strong>4. Ücret alacağının haczi, devri ve rehnedilmesi</strong></p>
      <p>
        <strong>MADDE 410-</strong> İşçilerin ücretinin dörtte birinden fazlası haczedilemez,
        başkasına devredilemez ve rehnedilemez. Ancak, işçinin bakmakla yükümlü olduğu aile
        bireyleri için hâkim tarafından takdir edilecek miktar, bu orana dâhil değildir. Nafaka
        alacaklılarının hakları saklıdır.
      </p>
      <p>Gelecekteki ücret alacaklarının devredilmesi veya rehnedilmesi geçersizdir.</p>
      <p><strong>5. Parça başına veya götürü iş</strong></p>
      <p><strong>a. İş verme</strong></p>
      <p>
        <strong>MADDE 411-</strong> İşçi, sözleşme gereğince yalnız bir işveren için sadece parça
        başına veya götürü iş yapmayı üstlenmişse işveren, ona yeterli iş vermekle yükümlüdür.
      </p>
      <p>
        İşveren, kendi kusuru olmaksızın sözleşmede öngörülen parça başına veya götürü iş
        sağlayamayacak durumda bulunduğu veya işletme koşulları geçici olarak gerektirdiği takdirde
        işçiye, ücreti zaman esasına göre öder. Bu durumda, zamana göre ödenecek ücret, anlaşmada
        veya hizmet ya da toplu iş sözleşmesinde belirlenmemişse işveren, işçiye parça başına veya
        götürü olarak daha önce aldığı ortalama ücrete eşdeğer bir ücret ödemekle yükümlüdür.
      </p>
      <p>
        Parça başına veya götürü ya da zamana göre iş sağlayamayan işveren, en azından işgörme
        edimini kabulde temerrüt hükümleri uyarınca zamana göre işgörmede ödeyeceği ücreti ödemekle
        yükümlüdür.
      </p>
      <p><strong>b. Birim ücreti</strong></p>
      <p>
        <strong>MADDE 412- </strong>İşçi, sözleşme gereğince parça başına veya götürü olarak
        çalışmayı üstlendiği takdirde işveren, her işin başlamasından önce ona ödenecek birim
        ücretini bildirmekle yükümlüdür.
      </p>
      <p>
        Bu bildirimi yapmayan işveren, aynı veya benzer bir iş için belirlenmiş olan birim ücretini
        ödemekle yükümlüdür.
      </p>
      <p><strong>II. İş araç ve malzemeleri</strong></p>
      <p>
        <strong>MADDE 413-</strong> Aksine anlaşma veya yerel âdet yoksa, işveren işçiye bu iş için
        gerekli araçları ve malzemeyi sağlamakla yükümlüdür.
      </p>
      <p>
        İşçi işverenle anlaşarak kendi araç veya malzemesini işin görülmesine özgülerse, aksi
        anlaşmada kararlaştırılmadıkça veya yerel âdet bulunmadıkça işveren, bunun için işçiye uygun
        bir karşılık ödemekle yükümlüdür.
      </p>
      <p><strong>III. Giderler</strong></p>
      <p><strong>1. Genel olarak</strong></p>
      <p>
        <strong>MADDE 414-</strong> İşveren, işin görülmesinin gerektirdiği her türlü harcama ile
        işçiyi işyeri dışında çalıştırdığı takdirde, geçimi için zorunlu olan harcamaları da
        ödemekle yükümlüdür.
      </p>
      <p>
        Yazılı olarak yapılmış bir hizmet veya toplu iş sözleşmesinde, bizzat işçi tarafından
        karşılanması kararlaştırılan harcamaların, işçiye götürü biçimde günlük, haftalık veya aylık
        olarak ödenmesi öngörülebilir. Ancak bu ödeme, zorunlu harcamaları karşılayacak miktardan az
        olamaz.
      </p>
      <p>
        Zorunlu harcamaların kısmen veya tamamen işçi tarafından bizzat karşılanmasına ilişkin
        anlaşmalar geçersizdir.
      </p>
      <p><strong>2. Taşıma araçları</strong></p>
      <p>
        <strong>MADDE 415- </strong>İşçi, işin görülmesi için işverenle anlaşarak işverenin veya
        kendisinin sağladığı bir taşıma aracı kullanıyorsa, taşıtın işletilmesi ve bakımı için
        gerekli olağan giderler, hizmet için kullanıldığı ölçüde işverence karşılanır.
      </p>
      <p>
        İşçi işverenle anlaşarak, işin görülmesinde kendi motorlu aracını kullanıyorsa, işveren
        ayrıca bu araçla ilgili vergiyi, zorunlu mali sorumluluk sigortası primini ve aracın
        yıpranması karşılığında uygun bir tazminatı hizmet için kullanıldığı ölçüde işçiye ödemekle
        yükümlüdür.
      </p>
      <p>
        İşçi işverenle anlaşarak, hizmetin görülmesinde kendisine ait diğer taşıma araçlarını ve
        hayvanlarını kullanıyorsa işveren, bunların kullanma ve bakımı için gerekli olan olağan
        giderleri hizmet için kullanıldığı ölçüde karşılamakla yükümlüdür.
      </p>
      <p><strong>3. Giderlerin ödenmesi</strong></p>
      <p>
        <strong>MADDE 416-</strong> İşçinin yapmış olduğu giderlerden doğan alacağı, daha kısa bir
        süre kararlaştırılmamışsa veya yerel âdet yoksa,<strong> </strong>her defasında ücretle
        birlikte ödenir.
      </p>
      <p>
        İşçi, sözleşmeden doğan borçlarını yerine getirmek için düzenli olarak masraf yapıyorsa,
        kendisine en az ayda bir olmak üzere belirli aralıklarla uygun bir avans verilir.
      </p>
      <p><strong>IV. İşçinin kişiliğinin korunması</strong></p>
      <p><strong>1. Genel olarak</strong></p>
      <p>
        <strong>MADDE 417-</strong> İşveren, hizmet ilişkisinde işçinin kişiliğini korumak ve saygı
        göstermek ve işyerinde dürüstlük ilkelerine uygun bir düzeni sağlamakla, özellikle işçilerin
        psikolojik ve cinsel tacize uğramamaları ve bu tür tacizlere uğramış olanların daha fazla
        zarar görmemeleri için gerekli önlemleri almakla yükümlüdür.
      </p>
      <p>
        İşveren, işyerinde iş sağlığı ve güvenliğinin sağlanması için gerekli her türlü önlemi
        almak, araç ve gereçleri noksansız bulundurmak; işçiler de iş sağlığı ve güvenliği konusunda
        alınan her türlü önleme uymakla yükümlüdür.
      </p>
      <p>
        İşverenin yukarıdaki hükümler dâhil, kanuna ve sözleşmeye aykırı davranışı nedeniyle işçinin
        ölümü, vücut bütünlüğünün zedelenmesi veya kişilik haklarının ihlaline bağlı zararların
        tazmini, sözleşmeye aykırılıktan doğan sorumluluk hükümlerine tabidir.
      </p>
      <p><strong>2. Ev düzeni içinde çalışmada</strong></p>
      <p>
        <strong>MADDE 418- </strong>İşçi işverenle birlikte ev düzeni içinde yaşıyorsa işveren,
        yeterli gıda ve uygun bir barınak sağlamakla yükümlüdür.
      </p>
      <p>
        İşçi, kusuru olmaksızın hastalık veya kaza gibi sebeplerle işgörme edimini yerine
        getiremezse işveren, sosyal sigortalar yardımlarından yararlanamayan, bir yıla kadar
        çalışmış işçinin bakımını ve tedavisini, iki hafta süreyle sağlamak zorundadır. İşçinin bir
        yılı aşan her hizmet yılı için söz konusu süre, dört haftayı aşmamak üzere ikişer gün
        artırılır.
      </p>
      <p>
        İşveren, işçinin gebeliğinde ve doğum yapması durumunda da aynı edimleri yerine getirmekle
        yükümlüdür.
      </p>
      <p><strong>3. Kişisel verilerin kullanılmasında</strong></p>
      <p>
        <strong>MADDE 419-</strong> İşveren, işçiye ait kişisel verileri, ancak işçinin işe
        yatkınlığıyla ilgili veya hizmet sözleşmesinin ifası için zorunlu olduğu ölçüde
        kullanabilir.
      </p>
      <p>Özel kanun hükümleri saklıdır.</p>
      <p><strong>V. Ceza koşulu ve ibra</strong></p>
      <p>
        <strong>MADDE 420- </strong>Hizmet sözleşmelerine sadece işçi aleyhine konulan ceza koşulu
        geçersizdir.
      </p>
      <p>
        İşçinin işverenden alacağına ilişkin ibra sözleşmesinin yazılı olması, ibra tarihi
        itibarıyla sözleşmenin sona ermesinden başlayarak en az bir aylık sürenin geçmiş bulunması,
        ibra konusu alacağın türünün ve miktarının açıkça belirtilmesi, ödemenin hak tutarına
        nazaran noksansız ve banka aracılığıyla yapılması şarttır. Bu unsurları taşımayan ibra
        sözleşmeleri veya ibraname kesin olarak hükümsüzdür.
      </p>
      <p>
        Hakkın gerçek tutarda ödendiğini ihtiva etmeyen ibra sözleşmeleri veya ibra beyanını muhtevi
        diğer ödeme belgeleri, içerdikleri miktarla sınırlı olarak makbuz hükmündedir. Bu hâlde
        dahi, ödemelerin banka aracılığıyla yapılmış olması zorunludur.
      </p>
      <p>
        İkinci ve üçüncü fıkra hükümleri, destekten yoksun kalanlar ile işçinin diğer yakınlarının
        isteyebilecekleri dâhil, hizmet sözleşmesinden doğan bütün tazminat alacaklarına da
        uygulanır.
      </p>
      <p><strong>VI. Tatil ve izinler</strong></p>
      <p><strong>1. Hafta tatili ve iş arama izni</strong></p>
      <p>
        <strong>MADDE 421- </strong>İşveren, işçiye her hafta, kural olarak pazar günü veya durum ve
        koşullar buna imkân vermezse, bir tam çalışma günü tatil vermekle yükümlüdür.
      </p>
      <p>
        İşveren, belirsiz süreli hizmet sözleşmesinin feshi hâlinde, bildirim süresi içinde işçiye
        ücretinde bir kesinti olmaksızın, günde iki saat iş arama izni vermekle yükümlüdür.
      </p>
      <p>
        İzin saatlerinin ve günlerinin belirlenmesinde, işyerinin ve işçinin haklı menfaatleri göz
        önünde tutulur.
      </p>
      <p><strong>2. Yıllık izin</strong></p>
      <p><strong>a. Süresi</strong></p>
      <p>
        <strong>MADDE 422-</strong> İşveren, en az bir yıl çalışmış olan işçilere yılda en az iki
        hafta ve onsekiz yaşından küçük işçiler ile elli yaşından büyük işçilere de en az üç hafta
        ücretli yıllık izin vermekle yükümlüdür.
      </p>
      <p><strong>b. İndirimi</strong></p>
      <p>
        <strong>MADDE 423- </strong>İşçi, bir hizmet yılı içinde kendi kusuruyla toplam bir aydan
        daha uzun bir süreyle hizmeti yerine getirmediği takdirde işveren, çalışılmayan her tam ay
        için, yıllık ücretli izin süresinden bir gün indirim yapabilir.
      </p>
      <p>
        İşçi, bir hizmet yılı içinde kendi kusuru olmaksızın hastalık, kaza, yasal bir yükümlülüğün
        veya kamu görevinin yerine getirilmesi gibi kişiliğine bağlı sebeplerle en çok üç ay süreyle
        işgörme edimini yerine getiremediği takdirde, işveren yıllık ücretli izin süresinden indirim
        yapamaz.
      </p>
      <p>
        İşveren, gebelik ve doğum yapma sebebiyle işgörme edimini en çok üç ay süreyle yerine
        getiremeyen kadın işçinin yıllık ücretli izin süresinden indirim yapamaz.
      </p>
      <p>
        Hizmet veya toplu iş sözleşmeleriyle, işçinin aleyhine hüküm doğuracak şekilde, ikinci ve
        üçüncü fıkra hükümlerine aykırı düzenleme yapılamaz.
      </p>
      <p><strong>c. Kullanılması</strong></p>
      <p>
        <strong>MADDE 424-</strong> Yıllık ücretli izinler, kural olarak aralıksız biçimde verilir;
        ancak tarafların anlaşmasıyla ikiye bölünerek de kullanılabilir.
      </p>
      <p>
        İşveren, yıllık ücretli izin tarihlerini, işyerinin veya ev düzeninin menfaatleriyle
        bağdaştığı ölçüde, işçinin isteklerini göz önünde tutarak belirler.
      </p>
      <p><strong>d. Ücreti</strong></p>
      <p>
        <strong>MADDE 425- </strong>İşveren, yıllık ücretli iznini kullanan her işçiye, yıllık
        ücretli izin süresine ilişkin ücretini, ilgili işçinin izne başlamasından önce peşin olarak
        ödemek veya avans olarak vermekle yükümlüdür.
      </p>
      <p>
        İşçi, hizmet ilişkisi devam ettiği sürece, işverenden alacağı para ve başka menfaatler
        karşılığında yıllık ücretli izin hakkından feragat edemez.
      </p>
      <p>
        Hizmet sözleşmesinin herhangi bir sebeple sona ermesi hâlinde, işçinin hak kazanıp da
        kullanamadığı yıllık izin sürelerine ait ücreti, sözleşmenin sona erdiği tarihteki ücreti
        üzerinden kendisine veya hak sahiplerine ödenir. Bu ücrete ilişkin zamanaşımı, hizmet
        sözleşmesinin sona erdiği tarihte işlemeye başlar.
      </p>
      <p><strong>VII. Hizmet belgesi</strong></p>
      <p>
        <strong>MADDE 426-</strong> İşveren, işçinin isteği üzerine her zaman, işin türünü<strong
        > </strong>ve süresini içeren bir hizmet belgesi vermekle yükümlüdür.
      </p>
      <p>
        İşçinin açıkça istemde bulunması hâlinde, hizmet belgesinde onun işgörmedeki becerisi ile
        tutum ve davranışları da belirtilir.
      </p>
      <p>
        Hizmet belgesinin zamanında verilmemesinden veya belgede doğru olmayan bilgiler
        bulunmasından zarar gören işçi veya işçiyi işe alan yeni işveren, eski işverenden tazminat
        isteyebilir.
      </p>
      <p><strong>E. Sınaî ve fikrî mülkiyet hakkı</strong></p>
      <p>
        <strong>MADDE 427- </strong>Hizmet buluşları üzerinde işçinin ve işverenin hakları, bunların
        kazanılması ile diğer sınaî ve fikrî mülkiyet hakları konusunda özel kanun hükümleri
        uygulanır.
      </p>
      <p><strong>F. Hizmet ilişkisinin devri</strong></p>
      <p><strong>I. İşyerinin tamamının veya bir bölümünün devri</strong></p>
      <p>
        <strong>MADDE 428</strong> İşyerinin tamamı veya bir bölümü hukuki bir işlemle başkasına
        devredildiğinde, devir tarihinde işyerinde veya bir bölümünde mevcut olan hizmet
        sözleşmeleri, bütün hak ve borçları ile birlikte devralana geçer.
      </p>
      <p>
        İşçinin hizmet süresine bağlı hakları bakımından, onun devreden işveren yanında işe
        başladığı tarih esas alınır.
      </p>
      <p>
        Yukarıdaki hükümlere göre devir hâlinde, devirden önce doğmuş olan ve devir tarihinde
        ödenmesi gereken borçlardan, devreden ve devralan işveren müteselsilen sorumludurlar. Ancak,
        devreden işverenin bu yükümlülüklerden doğan sorumluluğu, devir tarihinden itibaren iki yıl
        ile sınırlıdır.
      </p>
      <p><strong>II. Sözleşmenin devri</strong></p>
      <p>
        <strong>MADDE 429- </strong>Hizmet sözleşmesi, ancak işçinin yazılı rızası alınmak
        suretiyle, sürekli olarak başka bir işverene devredilebilir.
      </p>
      <p>
        Devir işlemiyle, devralan, bütün hak ve borçları ile birlikte, hizmet sözleşmesinin işveren
        tarafı olur. Bu durumda, işçinin, hizmet süresine bağlı hakları bakımından, devreden işveren
        yanında işe başladığı tarih esas alınır.
      </p>
      <p><strong>G. Sözleşmenin sona ermesi</strong></p>
      <p><strong>I. Belirli süreli sözleşmede</strong></p>
      <p>
        <strong>MADDE 430-</strong> Belirli süreli hizmet sözleşmesi,<strong> </strong>aksi
        kararlaştırılmadıkça, fesih bildiriminde bulunulmasına gerek olmaksızın, sürenin bitiminde
        kendiliğinden sona erer.
      </p>
      <p>
        Belirli süreli sözleşme, süresinin bitiminden sonra örtülü olarak sürdürülüyorsa, belirsiz
        süreli sözleşmeye dönüşür. Ancak, esaslı bir sebebin varlığı hâlinde, üst üste belirli
        süreli hizmet sözleşmesi kurulabilir.
      </p>
      <p>
        Taraflardan her biri, on yıldan uzun süreli hizmet sözleşmesini on yıl geçtikten sonra, altı
        aylık fesih bildirim süresine uyarak feshedebilir. Fesih, ancak bu süreyi izleyen aybaşında
        hüküm ifade eder.
      </p>
      <p>
        Sözleşmenin fesih bildirimiyle sona ereceği kararlaştırılmış ve iki taraf da fesih
        bildiriminde bulunmamışsa, sözleşme belirsiz süreli sözleşmeye dönüşür.
      </p>
      <p><strong>II. Belirsiz süreli sözleşmede</strong></p>
      <p><strong>1. Genel olarak fesih hakkı</strong></p>
      <p>
        <strong>MADDE 431-</strong> Taraflardan her birinin, belirsiz süreli sözleşmeyi fesih
        sürelerine uyarak feshetme hakkı vardır.
      </p>
      <p><strong>2. Fesih bildirim süresi</strong></p>
      <p><strong>a. Genel olarak</strong></p>
      <p>
        <strong>MADDE 432-</strong> Belirsiz süreli hizmet sözleşmelerinin feshinden önce, durumun
        diğer tarafa bildirilmesi gerekir.
      </p>
      <p>
        Hizmet sözleşmesi; bildirimin diğer tarafa ulaşmasından başlayarak, hizmet süresi bir yıla
        kadar sürmüş olan işçi için iki hafta sonra; bir yıldan beş yıla kadar sürmüş işçi için dört
        hafta ve beş yıldan fazla sürmüş işçi için altı hafta sonra sona erer.
      </p>
      <p>Bu süreler kısaltılamaz; ancak sözleşmeyle artırılabilir.</p>
      <p>
        İşveren, fesih bildirim süresine ait ücreti peşin vermek suretiyle hizmet sözleşmesini
        feshedebilir.
      </p>
      <p>
        Fesih bildirim sürelerinin, her iki taraf için de aynı olması zorunludur; sözleşmede farklı
        süreler öngörülmüşse, her iki tarafa da en uzun olan fesih bildirim süresi uygulanır.
      </p>
      <p>Hizmet sözleşmesinin askıya alındığı hâllerde fesih bildirim süreleri işlemez.</p>
      <p><strong>b. Deneme süresi içinde</strong></p>
      <p>
        <strong>MADDE 433-</strong> Taraflar, hizmet sözleşmesine iki ayı aşmamak koşuluyla deneme
        süresi koyabilirler. Deneme süresi konulmuşsa taraflar, bu süre içinde fesih süresine uymak
        zorunda olmaksızın, hizmet sözleşmesini tazminatsız feshedebilirler.
      </p>
      <p>İşçinin çalıştığı günler için ücret ve diğer hakları saklıdır.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "HizmetSozlesmesi"
};
</script>

<style scoped>
p,
p * {
  font-size: 0.8rem;
  line-height: 1.6;
}
</style>
