<template>
  <div>
    <div class="formpart">
      <div class="formfull">
        <div class="formleft">
          <h3>Fatura adresiniz</h3>
          <!-- <p>Farklı bir paket seçimi yapmak istiyorsanız tarayıcınızın navigasyon butonları ile bir önceki paket seçimi sayfasına geçiş yapabilirsiniz.</p> -->
          <p class="desc">
            Seçmiş olduğunuz pakete ilişkin ödeme aşamalarına geçmeden önce fatura adresi seçiminizi
            yapabilir, dilerseniz farklı bir adres bilgisi de girebilirsiniz.
          </p>

           <form action="#" onSubmit="return false;">
            <div class="halfgroup">
              <div class="half card" action="#" v-show="!show_yeni_adres">

                  <p>{{ payment.explicitAddress }}<br>{{ payment.district.name }} Mah.<br>{{ payment.town.name }}/{{ payment.city.name }}</p>

              </div>

              <div class="btn half second" v-if="!show_yeni_adres">
                <a @click="show_yeni_adres = true">Yeni adres belirlemek istiyorum</a>
              </div>

              <div v-if="show_yeni_adres" class="half" action="#">
                <div action="#">
                  <user-address
                    title="Lütfen fatura adresinizi giriniz:"
                    v-model="user_address"
                    :problem="problem.explicitAddress"
                  ></user-address>
                </div>

                <div
                  class="btn mb next"
                  :class="{ dsbl: !yeni_adres_ok }"
                  @click="USE_NEW_ADDRESS_CLICK"
                >
                  <a>Yeni Adresi Kullan</a>
                </div>
              </div>

              <div class="btn" v-if="show_yeni_adres">
                <a @click="USE_APPLICATION_ADDRESS_CLICK">Başvuruda Belirttğim Adresi Kullan</a>
              </div>

              <div class="btn" v-if="show_yeni_adres">
                <a @click="show_yeni_adres = false">Vazgeç</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";

export default {
  name : "FaturaAdresi",

  data() {
    return {
      show_yeni_adres: false,

      user_address: {
        city: { id: "", code: "", name: "" },
        town: { id: "", code: "", name: "" },
        district: { id: "", code: "", name: "" },
        explicitAddress: ""
      },

      problem: {
        explicitAddress: ""
      }
    };
  },

  created() {
    this.SET_PAYMENT_ADDRESS({
      ...this.application.address
    });
  },

  // mounted() {
  //   this.SET_PAYMENT_ADDRESS({
  //     ...this.application.address
  //   });
  // },

  computed: {
    ...mapState("applicant", ["user", "application"]),
    ...mapState("purchase", ["payment"]),

    yeni_adres_ok() {
      if (
        parseInt(this.user_address.city.id) > 0 &&
        parseInt(this.user_address.town.id) > 0 &&
        parseInt(this.user_address.district.id) > 0 &&
        this.user_address.explicitAddress.length > 2 &&
        this.user_address.explicitAddress.length < 251
      )
        return true;
      return false;
    }
  },

  watch: {
    user_address: {
      deep: true,
      handler() {
        this.problem.explicitAddress = "";
      }
    }
  },

  methods: {
    ...mapMutations("purchase", ["SET_PAYMENT_ADDRESS"]),

    async USE_NEW_ADDRESS_CLICK() {
      await this.$store.dispatch("app/friction2");

      if (this.yeni_adres_ok) {
        this.fatura_adresi = {
          ...this.user_address
        };
        this.show_yeni_adres = false;
        this.SET_PAYMENT_ADDRESS(this.user_address);
      } else {
        this.problem.explicitAddress =
          "Hatalı adres girdiniz! Tüm alanlar seçili ve adres satırı en az 3 en fazla 250 karakter olmalıdır!";
      }
    },

    async USE_APPLICATION_ADDRESS_CLICK() {
      await this.$store.dispatch("app/friction2");

      if (this.application.appNo) {
        this.fatura_adresi = {
          ...this.application.address
        };

        this.SET_PAYMENT_ADDRESS({
          ...this.application.address
        });
        this.show_yeni_adres = false;
      } else {
        this.Alert("Önce mevcut başvurunuzu sorgulamanız gerekmektedir!");
      }
    }
  }
};
</script>

<style scoped>

/*  on tablets portrait */
@media (orientation: portrait) and (min-width: 501px) {
}

/*  on big screens */
@media only screen and (min-height: 1000px) {
}

/*  on phones */
@media screen and (max-aspect-ratio: 13/9) and (max-width: 500px) {
}

</style>
