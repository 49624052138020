<template>
  <div :class="{ notvalid: problem }" class="forminput">
    <label>{{ label }}</label>
    <slot></slot>
    <input
      tabindex="0"
      ref="ref_myvalue"
      type="type"
      :readonly="fixed"
      :name="name"
      :class="{ fixed }"
      v-model="myValue"
      v-mask="{ mask, greedy: true }"
      :placeholder="placeholder"
      @input="$emit('input', sanitize ? sanitized(myValue) : myValue)"
      @blur="$emit('blur')"
      @keyup.enter="$emit('enter')"
      :focused="focused"
    />
    <div class="hint">{{ problem }}</div>
  </div>
</template>

<script>
export default {
  name: "IysInput",

  props: {
    focused: {
      type: Boolean,
      default: false
    },
    sanitize: {
      type: Boolean,
      default: true
    },
    value: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    fixed: {
      type: Boolean,
      default: false
    },
    mask: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    problem: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      myValue: ""
    };
  },

  mounted() {
    if (this.focused) this.$refs.ref_myvalue.focus();
  },

  watch: {
    value: {
      immediate: true,
      handler(n) {
        this.myValue = n;
        this.$emit("input", this.sanitize ? this.sanitized(n) : n);
      }
    }
  },

  methods: {
    sanitized(input) {
      if (this.fixed) return input;
      return input
        ? input
            .split("-")
            .join("")
            .split("_")
            .join("")
            .split("(")
            .join("")
            .split(")")
            .join("")
            .split(" ")
            .join("")
        : "";
    },
    maskCheck(e) {
      // console.log("MASK CHECK e");
    }
  }
};
</script>

<style scoped>
.fixed {
  pointer-events: none;
  background-color: #ebf6ff;
  border: #ffffff;
  font-weight: 600;
}
</style>
