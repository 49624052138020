<template>
  <div class="hizmet-sozlesmesi">
    <div>
      <p><strong>İLETİ YÖNETİM SİSTEMİ TEMEL HİZMETLER KULLANIM TAAHHÜTNAMESİ </strong></p>


<p class="mt"><b>1. Tanımlar</b></p>
<p><b>Alıcı:</b> Hizmet Sağlayıcı’nın gerçekleştirdiği ticari iletişimin muhatabı olan tüketici ya da meslekî veya diğer amaçlarla hareket eden gerçek veya tüzel kişi. </p>
<p><b>Aracı hizmet sağlayıcı:</b> Başkalarına ait iktisadî ve ticari faaliyetlerin yapılmasına elektronik ticaret ortamını sağlayan gerçek ve tüzel kişileri.</p>
<p><b>Elektronik İletişim Adresi:</b> Alıcı’ya ait elektronik posta adresi ve telefon numarası gibi elektronik ortamda münhasıran iletişim kurmayı sağlayan adres(ler).</p>
<p><b>Hizmet Sağlayıcı:</b> Ticari elektronik ileti gönderimi yapan ve İYS’ye kaydolan, bu Taahhütname’nin tarafı olan gerçek veya tüzel kişi. </p>
<p><b>İYS:</b> Ticari Elektronik İleti onayı alınmasına, reddetme hakkının kullanılmasına ve şikâyet süreçlerinin yönetilmesine Mevzuat kapsamında imkân tanıyan tüm sistem, platform, internet sitesi ve mobil uygulamaları kapsayan İleti Yönetim Sistemi. </p>
<p><b>İYS A.Ş:</b> İleti Yönetim Sistemi Anonim Şirketi.</p>
<p><b>Mevzuat:</b> 6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun ve Ticari İletişim ve Ticari Elektronik İletiler Hakkında Yönetmelik başta olmak üzere uygulanabilir tüm mevzuat. </p>
<p><b>Ticari Elektronik İleti:</b> Telefon, çağrı merkezi, faks, otomatik arama makinesi, akıllı ses kaydedici sistem, elektronik posta, kısa mesaj hizmeti gibi vasıtalar kullanılarak elektronik ortamda gerçekleştirilen ve ticari amaçlarla gönderilen veri, ses ve görüntü içerikli iletiler.</p>
<p><b>Yetkili Kullanıcı:</b> Hizmet Sağlayıcı’nın İYS’yi kullanmak üzere yetkilendirdiği kişi.</p>

<p class="mt"><b>2. Taahhütname’nin Konusu</b></p>
<p>İşbu Taahhütname’nin konusu Hizmet Sağlayıcı’nın; İYS’ye kaydolması, İYS üzerinden Alıcılar’ın onay ve ret süreçlerinin takibi ve İYS’yi kullanımına ilişkin usul ve esasların belirlenmesi ve bu kapsamdaki yükümlülüklerinin belirlenmesidir.</p>

<p class="mt"><b>3. İYS’ye Kayıt ve İYS’nin Kullanımı</b> </p>
<p class="indent1"><b>3.1.</b> Hizmet Sağlayıcı, İYS’ye kaydolurken İYS A.Ş. tarafından talep edilen tüm bilgi ve belgeleri İYS A.Ş. tarafından talep edildiği şekilde eksiksiz ve doğru şekilde sağlayacağını, kayıt sırasında sağladığı bilgi ve belgelerde değişiklik olması halinde bu değişiklikleri İYS A.Ş.’ye derhâl bildireceğini kabul, beyan ve taahhüt eder. </p>

<p class="indent1"><b>3.2.</b> İYS A.Ş., Hizmet Sağlayıcı tarafından İYS’ye kayıt için sağlanan bilgi ve belgelerin doğruluğunu kontrol edecektir. Sağlanan belgelerde sahtecilik ya da eksiklik olduğunun tespit edilmesi, sağlanan bilgiler ile belgeler arasında tutarsızlık olması veya sağlanan bilgi ve belgelerin sair nedenlerle İYS A.Ş. tarafından şüpheli olarak değerlendirilmesi halinde İYS A.Ş., Hizmet Sağlayıcı’nın İYS kaydını tamamlamak için yeni bilgi ve belgeler talep edebilecek ve/veya Hizmet Sağlayıcı’dan gerekli değişiklikleri yapmasını isteyebilecektir. Hizmet Sağlayıcı’nın talep edilen bilgi ve belgeleri tam ve doğru olarak sağlaması halinde İYS başvurusu İYS A.Ş. tarafından onaylanacaktır. </p>

<p class="indent1"><b>3.3.</b> Hizmet Sağlayıcı’nın işbu Taahhütname’yi İYS A.Ş. tarafından bildirilen yöntemlerle onaylaması ve İYS A.Ş. tarafından talep edilen bilgi ve belgelerin sağlanmasından sonra İYS A.Ş.’nin gerekli kontrolleri tamamlayıp Hizmet Sağlayıcı’nın İYS’ye kaydını onaylamasıyla birlikte işbu Taahhütname yürürlüğe girecektir. </p>

<p class="indent1"><b>3.4.</b> Hizmet Sağlayıcı, İYS’yi kullanmak üzere Yetkili Kullanıcı tanımlayabilecektir. Hizmet Sağlayıcı’nın, Yetkili Kullanıcı’nın İYS hesabına ilişkin şifre ve sair kimlik doğrulama ve oturum açma bilgilerinin korunması, bunların gizlilik ve güvenliğinin sağlanması adına üçüncü kişilerle paylaşılmamasından Hizmet Sağlayıcı sorumludur. Hizmet Sağlayıcı, İYS hesabının ve bu hesaba ilişkin bilgilerin Yetkili Kullanıcı dışında üçüncü kişilerce kullanılmaması için gerekli tüm tedbirleri alacak ve söz konusu bilgilerin üçüncü kişiler tarafından ele geçirildiğinin veya kullanıldığının öğrenilmesi veya İYS hesabının gizlilik ve güvenliğine ilişkin herhangi bir şüphe bulunması halinde bu durumu derhâl İYS A.Ş.’ye bildirecektir. </p>

<p class="indent1"><b>3.5.</b> Hizmet Sağlayıcı, Yetkili Kullanıcı’nın İYS’yi işbu Taahhütname, Mevzuat ve İYS A.Ş. tarafından bildirilen sair kurallara uygun şekilde kullanmasını sağlayacağını, Yetkili Kullanıcı’nın tüm işlem ve eylemlerinden münhasıran sorumlu olduğunu, Hizmet Sağlayıcı’nın ve Yetkili Kullanıcı’nın şifre, sair kimlik doğrulama bilgileri ve oturum açma bilgileri kullanılarak İYS hesabı üzerinden yapılacak işlemlerin kendisi tarafından yapılmış sayılacağını kabul ve beyan eder. </p>

<p class="indent1"><b>3.6.</b> Hizmet Sağlayıcı, ilgili Mevzuata uygun şekilde, Alıcılar’dan temin ettiği Ticari Elektronik İleti onaylarını ve her halükarda İYS dışında kanallardan temin ettiği onayları ve ret bildirimlerini yine ilgili Mevzuat ile belirlenen sürelerde İYS A.Ş. tarafından talep edilen yöntemlerle ve İYS A.Ş. tarafından talep edilen bilgileri içerecek şekilde İYS’ye aktaracaktır. </p>


<p class="mt"><b>4. Temel Hizmetler ve Başlangıç Hizmeti</b></p>
<p class="indent1"><b>4.1.</b> Hizmet Sağlayıcı, İYS üzerinden sunulan ve kapsamı aşağıda belirtilen temel hizmetlerden ücretsiz olarak faydalanabilecektir. </p>

<p class="indent11"><b>4.1.1.</b> Ticari Elektronik İleti Onayı Ekleme: Hizmet Sağlayıcı, İYS’ye giriş yaparak izin yönetimi ekranından yeni bir onay oluşturur, ön tanımlı kaynaklar arasından onay alınan iletişim kanalını seçer ve Alıcı’nın onay verdiği iletişim kanalını ekler. Hizmet Sağlayıcı’nın her bir onay ekleme işlemi her bir iletişim adresi için aynı adımlar tekrarlanarak gerçekleştirilir.</p>

<p class="indent11"><b>4.1.2.</b> Ticari Elektronik İleti Onayı Sorgulama: Hizmet Sağlayıcı, İYS’de onaylı olarak kayıtlı olan bir Alıcı’ya ilişkin sorgu yapmak istediğinde izin yönetimi ekranındaki sorgulama alanını kullanır. Arama kutucuğuna Alıcı’ya ait onay eklenirken belirtilen Elektronik İletişim Adresi yazılır. Telefon numarası ile yapılan aramalarda telefon numarasının tüm hanelerinin eksiksiz girilmesi, e-posta aramalarında ise adresin tam olarak yazılması gerekir. İlgili sorgulamanın ardından Alıcı’nın onay durumu görüntülenir.</p>

<p class="indent11"><b>4.1.3.</b> Alıcı’nın Onay/Ret Değişikliği: Alıcı’nın, Hizmet Sağlayıcı’ya vermiş olduğu Ticari Elektronik İleti’nin onay durumunda iki şekilde değişiklik gerçekleşebilir:</p>
<p class="indent111"><b>(i)</b> Alıcı, İYS üzerinden mevcut onay durumunda değişiklik yaparak, vermiş olduğu onayı redde, reddi ise onaya çevirebilir. Bu durumda, yapılan değişiklikleri İYS A.Ş. Hizmet Sağlayıcı’ya gün sonunda raporlar.</p>
<p class="indent111"><b>(ii)</b> Hizmet Sağlayıcı, İYS dışındaki kanallarda Alıcı’nın onay/ret değişikliği talebini alabilir. Bu durumda, Hizmet Sağlayıcı onay durumunu değiştireceği Alıcı’nın Elektronik İletişim Adresi’ni arama kutucuğunda sorgular. Sorgulama sonucunda Alıcı’ya ait mevcut onayı, Alıcı’nın değişiklik talebi doğrultusunda onay veya ret şeklinde düzenler. Hizmet Sağlayıcı, Alıcı’dan gelen onay ve ret durumuna ilişkin değişiklik taleplerini Mevzuat’ta belirlenen süre içinde İYS’de güncellemekle yükümlüdür.</p>
<p class="indent11"><b>4.1.4.</b> Raporlama: Hizmet Sağlayıcı, her gün sonunda bir önceki gün içinde İYS üzerinde, Ticari Elektronik İleti gönderme onayı bulunan Elektronik İletişim Adresleri bakımından gerçekleşen değişiklikleri gösteren zaman damgalı rapor alabilir. İYS üzerinden, bu şekilde oluşturulmuş ve İYS tarafından ilan edilecek geçmiş gün sayısı adedi kadar rapora erişmek mümkündür.</p>

<p class="indent1"><b>4.2.</b> Hizmet Sağlayıcı, kayıtlı Elektronik İletişim Adresi sayısı iys.org.tr web sitesinde ilan edilecek adedi geçmemek kaydıyla, Ticari Elektronik İletiler’in İYS üzerinden onay ve ret süreçlerini kapsayan başlangıç hizmetini alabilir. Söz konusu başlangıç hizmetinin şartları ve bu hizmetten yararlanabilmek için uyulması gereken usuller iys.org.tr web sitesinde güncel olarak bulunmaktadır.</p>

<p class="indent1"><b>4.3.</b> Hizmet Sağlayıcı, İYS üzerinden sunulan, madde 4.1 ve madde 4.2’de sayılanlar dışındaki hizmetlerden yararlanmak istediğinde İYS A.Ş. ile bu hizmetlere ilişkin ayrı bir sözleşme akdetmesi gerektiğini kabul eder. </p>


<p class="mt"><b>5. Hak ve Yükümlülükler</b></p>
<p class="indent1"><b>5.1.</b> Hizmet Sağlayıcı, İYS’yi yalnızca Mevzuat hükümleri çerçevesinde Ticari Elektronik İleti süreçlerinin yönetimi için kullanabileceğini, sair amaçlarla İYS’ye erişimin ve İYS’nin kullanımının yasak olduğunu bildiğini kabul ve beyan eder. Alınan Ticari Elektronik İleti onaylarının ve gönderilen Ticari Elektronik İletiler’in içeriklerinin Mevzuat’a uygun olması, Alıcılar’ın onay ve ret bildirimlerinin takibinin yapılması, aracı hizmet sağlayıcılarla gerekli anlaşmaların yapılması, Mevzuat kapsamındaki ispat yükümlülüklerinin yerine getirilmesi dahil ancak bunlarla sınırlı olmamak üzere İYS’nin kullanımına ilişkin tüm sorumluluk münhasıran Hizmet Sağlayıcı’ya aittir. Hizmet Sağlayıcı, İYS A.Ş.’nin yükümlülüğü Hizmet Sağlayıcı’nın Mevzuat’a uyum için kaydolmakla yükümlü olduğu platformun teknolojik altyapısının sağlanmasından ibaret olup Hizmet Sağlayıcı İYS’ye kaydolmasının Mevzuat’tan kaynaklanan tüm yükümlülüklerin yerine getirilmesi için yeterli olmadığını yahut herhangi bir yükümlülüğünü ortadan kaldırmadığını kabul ve beyan eder.</p>

<p class="indent1"><b>5.2.</b> Hizmet Sağlayıcı, İYS üzerinden yayımlanan her türlü kılavuz, rehber ve sair kural ve koşula uymak zorunda olup bunlar işbu Taahhütname’nin eki ve ayrılmaz parçasıdır. Hizmet Sağlayıcı, İYS üzerinden yapılan bildirimleri takip etmekle yükümlüdür.</p> 

<p class="indent1"><b>5.3.</b> İYS A.Ş., işbu Taahhütname hüküm ve koşulları ile İYS üzerinden yayımlanan kural ve koşulları zaman zaman değiştirebilecek veya güncelleyebilecektir. İşbu Taahhütname’de yapılan değişiklikler İYS A.Ş. tarafından Hizmet Sağlayıcı’ya bildirilecek olup değişiklikler bildirim ile birlikte yürürlüğe girecektir. İYS üzerinden yayımlanan kural ve koşullarda yapılan değişiklikler değişiklik/güncelleme tarihi itibarıyla ayrıca bir bildirim olmaksızın yürürlüğe girecektir.  </p>

<p class="indent1"><b>5.4.</b> Hizmet Sağlayıcı, İYS’ye yüklenecek olan Alıcı kişisel verileri ile İYS A.Ş.’ye bildireceği Yetkili Kullanıcı kişisel verileri bakımından 6698 sayılı Kişisel Verilerin Korunması Kanunu ve ilgili Mevzuat kapsamındaki yükümlülüklerin yerine getirilmesinin münhasıran kendi sorumluluğunda olduğunu, İYS A.Ş.’nin Mevzuat’tan doğan yükümlülükleri saklı olmak üzere, Alıcılar’ın ve Yetkili Kullanıcı’nın kişisel verileri bakımından herhangi bir sorumluluğu bulunmadığını kabul eder.</p>

<p class="indent1">Hizmet Sağlayıcı, kişisel verilerin hukuka aykırı olarak işlenmesini önlemek, muhafazasını sağlamak ve Kişisel verilere hukuka aykırı olarak erişilmesini önlemek amacıyla azami güvenlik düzeyini temin edici tüm teknik ve idari tedbirleri aldığını ve alacağını kabul ve taahhüt eder.  Bu hükmün ihlali halinde doğabilecek hukuki, idari ve cezai sorumluluk Hizmet Sağlayıcı'ya ait olup, işbu hükümlerin ihlalinden doğacak her türlü zararın tazmini Hizmet Sağlayıcı'ya aittir. Hizmet Sağlayıcı, bu zararları derhal tazmin etmekle mükelleftir. Böyle bir ihlalin gerçekleşmesi durumunda ve işlenen kişisel verilerin kanuni olmayan yollarla başkaları tarafından elde edilmesi hâlinde, bu durumu derhal İYS A.Ş. ve Kişisel Verileri Koruma Kurumu'na bildirir. </p>


<p class="indent1">Hizmet Sağlayıcı, işbu sözleşme kapsamında İYS A.Ş.'den gelen soruları mümkün olan en kısa sürede usulüne uygun olarak cevaplandırır ve aktarıma konu Kişisel Verilerin işlenmesi hususunda Kişisel Verileri Koruma Kurumu karar ve görüşlerine uyar.</p>

	
<p class="indent1"><b>5.5.</b> İYS A.Ş., İYS’ye veri aktarımı için veri aktarım yöntemleri ve formatları ile İYS’nin kullanımı gerekli olan için teknik spesifikasyon ve güvenlik standartlarını belirlemeye ve bunları muhtelif zamanlarda değiştirmeye münhasıran yetkilidir. Hizmet Sağlayıcı, İYS A.Ş. tarafından bu kapsamda belirlenen kural ve standartlara uymakla yükümlü olduğunu, bunlara uymaması halinde hizmetlerden faydalanamayabileceğini, bu hallerde İYS A.Ş.’ye karşı herhangi bir iddia veya talep yöneltemeyeceğini kabul, beyan ve taahhüt eder. </p>

<p class="indent1"><b>5.6.</b> İYS A.Ş., Hizmet Sağlayıcı’nın Taahhütname ya da Mevzuat’taki yükümlülüklerini yerine getirmemesi nedeniyle meydana gelebilecek zararlar, Hizmet Sağlayıcı’nın kullandığı tüm donanım, yazılım ve ağ ilişkili işlevden ve bu nedenle ortaya çıkacak arızalar, aracı hizmet sağlayıcıların fiilleri, iletişim ağı (network) tasarım ve bağlantı hataları, voltaj dalgalanmaları, elektrik kesilmeleri, virüs bulaşması ve benzeri çevresel faktörler dahil ancak bunlarla sınırlı olmamak üzere Hizmet Sağlayıcı’nın ve/veya Yetkili Kullanıcı’nın İYS’yi kullanımı ile ilişkili olarak meydana gelen zararlardan sorumlu değildir.</p>


<p class="mt"><b>6. Fikrî Mülkiyet Hakları</b></p> 
<p>İYS içerisindeki görsel ve tasarımlar, yazılar, logolar, grafikler dahil olmak üzere İYS’ye ilişkin tüm fikri mülkiyet hakları ya da bunları kullanma yetkisi İYS A.Ş.’ye aittir. Hizmet Sağlayıcı’nın İYS sistemlerinin güvenliğini tehdit edebilecek ve/veya İYS ve kullanıcılarına zarar verebilecek eylemlerde bulunması, İYS’nin çalışmasını veya İYS’den faydalanılmasını engelleyebilecek herhangi bir girişimde bulunması, bu sonuçları verecek şekilde sisteme orantısız yük bindirmesi, İYS sistemlerine yetkisiz bir şekilde erişmesi, bu bilgileri kopyalaması, silmesi, değiştirmesi ya da bu yönde denemeler yapması, İYS’nin çalışmasını engelleyecek yazılımlar kullanması, kullanmaya çalışması veya her türlü yazılım, donanım ve sunucuların çalışmasını aksatması, bozulmasına yol açması, tersine mühendislik yapması, saldırılar düzenlemesi, meşgul etmesi veya bunlara sair surette müdahale etmesi, sunuculara erişim sağlamaya çalışması yasaktır. </p>


<p class="mt"><b>7. Muhtelif Hükümler</b></p>
<p class="indent1"><b>7.1.</b> İşbu Taahhütname’de yer almayan hususlarda Mevzuat hükümleri uygulanacaktır. İlgili Mevzuat kapsamındaki herhangi bir değişiklik nedeniyle Taahhütname’de değişiklik gerektiği takdirde, İYS A.Ş. tarafından Taahhütname’de gerekli değişiklikler yapılacaktır. İlgili Mevzuat’taki söz konusu değişiklikler, Taahhütname’de değişiklik yapılıp yapılmadığına bakılmaksızın ilgili yasal düzenlemenin yürürlük tarihi itibarıyla yürürlüğe girmiş kabul edilecektir.</p>

<p class="indent1"><b>7.2.</b> İşbu Taahhütname’den kaynaklanan uyuşmazlıklarda Ankara Mahkemeleri ve İcra Daireleri yetkili olacaktır.</p>

<p class="indent1"><b>7.3.</b> İşbu Taahhütname’nin esaslı olmayan hükümlerinden biri ya da birkaçının kısmen veya tamamen geçersiz addedilmesi, Taahhütname’nin kalan hükümlerinin geçerliliğine etki etmeyecektir.</p>


    </div>
  </div>
</template>

<script>
export default {
  name: "KullanimTaahhutnamesi"
};
</script>

<style scoped>

.hizmet-sozlesmesi {
    height: unset;
}

p,
p * {
  font-size: 1rem;
  line-height: 1.5;
}
</style>
